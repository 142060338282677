<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Programme</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
<!--      <v-btn-->
<!--        rounded-->
<!--        color="primary"-->
<!--        class="mb-5"-->
<!--        x-small-->
<!--        large-->
<!--        @click="openDialogProgramme"-->
<!--        v-if="canAccess('programme-create')"-->
<!--      >-->
<!--        <v-icon color="white" dark>mdi-plus</v-icon-->
<!--        ><span style="color: white">Add Programme</span>-->
<!--      </v-btn>-->
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSortProgramme"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sort Programme</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12" class="p-0 m-0 pt-10">
            <div class="d-flex pl-5">
              <label class="text-body-1 font-weight-medium mb-6">Status:</label>
              <v-radio-group class="mt-0 pt-0" v-model="status_programme" row>
                <v-radio label="Active" :value="1"></v-radio>
                <v-radio label="Deactivated" :value="2"></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              class="mt-5"
              color="#00BCD4"
              style="float: left"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="6">
            <b-form-input
              class="mt-6"
              style="float: right; width: 300px"
              placeholder="Search Name"
              v-model="data_search"
              @keyup.enter="actionSearchProgramme()"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Cover</th>
                    <th scope="col">Tag line</th>
                    <th scope="col">Name</th>
                    <!-- <th scope="col">Short Description</th> -->
                    <th scope="col">Lock</th>
                    <!--                    <th scope="col">Status</th>-->
                    <th scope="col">Price</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(programme, i) in programmes">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td class="">
                        <v-img
                          v-if="programme.cover"
                          :src="programme.cover.vi"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td>
                        <template v-if="programme.tag_line">
                          <p
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            style="max-width: 300px"
                          >
                            {{ programme.tag_line.vi }}
                          </p>
                        </template>
                      </td>
                      <td>
                        <template v-if="programme.name">
                          <p
                            class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            style="max-width: 300px"
                          >
                            {{ programme.name.vi }}
                          </p>
                        </template>
                      </td>
                      <!-- <td>
                        <template v-if="programme.short_description">
                          <p class="text-dark-75 font-weight-normal d-block font-size-lg" style="max-width: 650px;">{{programme.short_description.vi}}</p>
                        </template>
                      </td> -->
                      <td>
                        <span
                          class="label label-lg label-inline font-weight-bold py-4"
                          v-bind:class="
                            programme.is_locked === 0
                              ? 'label-light-success'
                              : 'label-light-warning'
                          "
                        >
                          {{ programme.is_locked === 0 ? "Unlock" : "Locked" }}
                        </span>
                      </td>
                      <!--                      <td>-->
                      <!--                        <span style="width: 137px;" v-if="programme.is_active === 0"><span class="label label-danger label-dot mr-2"></span><span class="font-weight-bold text-danger">Deactivated</span></span>-->
                      <!--                        <span style="width: 137px;" v-if="programme.is_active === 1"><span class="label label-primary label-dot mr-2"></span><span class="font-weight-bold text-primary">Activated</span></span>-->
                      <!--                      </td>-->
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg blue--text text--lighten-1 mt-4"
                          v-if="programme.price !== 0"
                        >
                          {{ programme.price | formatNumber }} VND
                        </p>
                        <span
                          v-if="programme.price === 0"
                          class="label label-lg label-inline font-weight-bold py-4 label-light-warning"
                          >Free</span
                        >
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'ProgrammeDetail',
                                  params: { id: programme.id },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-primary btn-sm"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color="">mdi-eye-settings</v-icon>
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Detail Programme</span>
                          </v-tooltip>
<!--                          <v-tooltip top>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-warning btn-sm ml-2"-->
<!--                                v-bind="attrs"-->
<!--                                v-if="canAccess('programme-update')"-->
<!--                                v-on="on"-->
<!--                                @click="detailProgramme(i)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color=""-->
<!--                                    >mdi-square-edit-outline</v-icon-->
<!--                                  >-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Edit Programme</span>-->
<!--                          </v-tooltip>-->
<!--                          <v-tooltip top>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <router-link-->
<!--                                :to="{-->
<!--                                  name: 'ProgrammeFaqList',-->
<!--                                  params: { programme_id: programme.id },-->
<!--                                }"-->
<!--                                target="_blank"-->
<!--                              >-->
<!--                                <button-->
<!--                                  class="btn btn-icon btn-light-success btn-sm ml-2"-->
<!--                                  v-bind="attrs"-->
<!--                                  v-on="on"-->
<!--                                >-->
<!--                                  <span-->
<!--                                    class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                  >-->
<!--                                    <v-icon color=""-->
<!--                                      >mdi-frequently-asked-questions</v-icon-->
<!--                                    >-->
<!--                                  </span>-->
<!--                                </button>-->
<!--                              </router-link>-->
<!--                            </template>-->
<!--                            <span>Các câu hỏi (faq)</span>-->
<!--                          </v-tooltip>-->
<!--                          <v-tooltip right>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-danger btn-sm ml-2"-->
<!--                                v-bind="attrs"-->
<!--                                v-if="canAccess('programme-delete')"-->
<!--                                v-on="on"-->
<!--                                @click="deleteProgramme(programme.id, i)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color="">mdi-delete</v-icon>-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Delete Programme</span>-->
<!--                          </v-tooltip>-->
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!programmes.length">
                    <tr>
                      <td style="text-align: center" colspan="8">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>

    <ProgrammeForm
      :show_dialog="dialogProgramme"
      :type_form="type_form_programme"
      :tags="tags"
      :teacher_tags="teacher_tags"
      :course_tags="course_tags"
      :programme="programme"
      :category_all_sub="category_all_sub"
      @setDialog="(e) => (dialogProgramme = e)"
      @resetList="resetListProgramme"
    ></ProgrammeForm>

    <v-row justify="center">
      <v-dialog
        v-model="dialogSortProgramme"
        scrollable
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sort Lesson/ Test</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSortProgramme = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(programme, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <p class="mb-0 text-subtitle-1">
                          {{ programme.name.vi }}
                        </p>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="sortCourseItems"
              >Update</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="dialogSortProgramme = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import handle_ls_search from "@/mixins/handle_ls_search.js";
export default {
  name: "ProgrammeList",
  mixins: [handle_ls_search],
  components: {
    ProgrammeForm: () => import("@/view/pages/programme/modal/ProgrammeForm"),
    Loading: () => import("vue-loading-overlay"),
    draggable: () => import("vuedraggable"),
  },
  data() {
    return {
      is_call_api: false,
      type_form_programme: null,
      data_search: "",
      programmes: [],
      programme: {},
      dialogProgramme: false,
      course_tags: [],
      teacher_tags: [],
      dialogSortProgramme: false,
      data_draggable: [],
      tags: [],
      timer_query: null,
      status_programme: 1,
      category_all_sub: [],
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
    };
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    // data_search(val) {
    //   this.actionSearchProgramme(val);
    // },
    status_programme() {
      this.getAllProgramme(1, "");
    },
    "data_pagination.current_page": async function (page) {
      this.is_call_api = true;
      await this.getAllProgramme(page, this.data_search);
      this.is_call_api = false;
    },
  },
  computed: {
    // filteredProgrammes() {
    //   if(this.programmes.length) {
    //     return this.programmes.filter(programme => {
    //       return programme.name.en.toLowerCase().includes(this.data_search.toLowerCase())
    //     })
    //   } else {
    //     return [];
    //   }
    // }
  },
  created() {
    this.status_programme = 1;
    if (this.canAccess("programme-view")) {
      let data_search = this.getDataSearchByKey("programme");
      if (data_search) {
        this.data_search = data_search.query ? data_search.query : "";
      }
      this.is_call_api = true;
      this.getCategoryAllSub();
      this.getAllCourse();
      this.getAllTeacher();
      this.getAllTag();
      this.getAllProgramme(1, this.data_search);
      this.is_call_api = false;
    } else {
      this.$router.push({ name: "403" });
    }
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async getCategoryAllSub() {
      try {
        let res = await ApiService.get("prep-app/product-category/all-sub");
        if (res.status === 200) {
          this.category_all_sub = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllTag() {
      try {
        let res = await ApiService.get("prep-app/tag/limit");
        if (res.status === 200) {
          this.tags = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllProgramme(page = 1, query = "") {
      let vm = this;
      let is_active = this.status_programme === 1 ? 1 : 0;
      this.is_call_api = true;
      await ApiService.get(
        "prep-app/programme?is_active=" + is_active + "&page=" + page + "&query=" + query
      )
        .then(function (response) {
          if (response.status === 200) {
            vm.programmes = response.data.data;
            vm.data_pagination = response.data.meta.pagination;
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          console.log(error);
        });
    },
    async getAllCourse() {
      let seft = this;
      await ApiService.get("prep-app/courses/limit").then(function (response) {
        if (response.status === 200) {
          seft.course_tags = response.data;
        }
      });
    },
    async getAllTeacher() {
      let self = this;
      await ApiService.get("prep-app/teachers").then(function (response) {
        if (response.status === 200) {
          self.teacher_tags = response.data;
        }
      });
    },
    openDialogProgramme() {
      this.dialogProgramme = true;
      this.type_form_programme = "add";
    },
    async resetListProgramme() {
      this.is_call_api = true;
      await this.getAllProgramme(
        this.data_pagination.current_page,
        this.data_search
      );
      this.is_call_api = false;
    },
    detailProgramme(i) {
      this.dialogProgramme = true;
      this.type_form_programme = "update";
      this.programme = this.programmes[i];
      // this.language = "vi";
      // let course_ids = this.programmes[i].courses.map((course) => {
      //     return course.id;
      // })
      // this.course_ids = course_ids;
      // this.idProgramme = id_programme;
      // this.name_input = this.programmes[i].name;
      // this.description_input = this.programmes[i].description;
      // this.cover_input.en = this.programmes[i].cover.en;
      // this.cover_file.en = null;
      // this.cover_input.vi = this.programmes[i].cover.vi;
      // this.cover_file.vi = null;
      // this.price_input  = this.formatMoney(this.programmes[i].price);
      // this.short_description_input  = this.programmes[i].short_description;
      // this.is_featured  = (this.programmes[i].is_featured === 1) ? 'yes' : 'no';
      // this.for_partners_only  = (this.programmes[i].for_partners_only === 1) ? 'yes' : 'no';
      // this.is_active = this.programmes[i].is_active;
      // this.tag_line_input = (this.programmes[i].tag_line == null) ? { vi: "", en: "" }: this.programmes[i].tag_line;
      // this.study_guide_input = (this.programmes[i].study_guide == null) ? '' : this.programmes[i].study_guide;
      // this.is_locked = this.programmes[i].is_locked === 1;
      // this.unlock_at_input = (this.programmes[i].is_locked === 1) ? this.$options.filters.convertTimestampToDate(this.programmes[i].unlocked_at) : null;
      // this.tag_selected = this.programmes[i].tags;
      // this.study_guide_data = (this.programmes[i].instruction_file === "") ? null : this.programmes[i].instruction_file;
      // this.study_guide_file = null;
    },
    deleteProgramme(id_programme, index) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete("prep-app/programme/" + id_programme)
            .then(function (res) {
              if (res.status === 202) {
                // self.resetListProgramme();
                vm.programmes.splice(index, 1);
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
    openDialogSortProgramme() {
      this.data_draggable = [...this.programmes];
      this.dialogSortProgramme = true;
    },
    sortCourseItems() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = vm.data_draggable.map((programme, index) => {
            return {
              programme_id: programme.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.put("prep-app/programme/sort", data)
            .then(function (res) {
              if (res.status === 200) {
                vm.is_call_api = false;
                vm.getAllProgramme();
                Swal.fire({
                  title: "",
                  text: "Sắp xếp thành công",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                vm.dialogSortProgramme = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
    actionSearchProgramme() {
      let query = this.data_search;
      let vm = this;
      if (this.timer_query) {
        clearTimeout(this.timer_query);
        this.timer_query = null;
      }
      this.timer_query = setTimeout(function () {
        let programme = {
          query: query,
        };
        vm.getAllProgramme(1, query);
        vm.saveDataSearchToLs("programme", programme);
      }, 500);
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
.description-teacher {
  max-width: 150px;
  max-height: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.email-teacher {
  font-style: italic;
  font-weight: bold;
  color: gray;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.drag-css {
  cursor: pointer;
}
</style>
